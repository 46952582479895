import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, DatePicker, Modal, Space, Typography } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { changePlanRevocationDate, workspaceQueries } from '../../../../../Actions'

type Props = {
  workspaceId: number
  scheduleId: number
  initialTime: string
}

export default function ChangeInvokeTimeButton({ workspaceId, scheduleId, initialTime }: Props) {
  const [modalOpened, setModalOpened] = useState(false)
  const [revokeTime, setRevokeTime] = useState(dayjs(initialTime))
  const [message, setMessage] = useState('')

  const { mutate: mutateToCancelSchedulePlans, isPending: isCancelingSchedulePlans } = useMutation({
    mutationFn: changePlanRevocationDate,
  })
  const queryClient = useQueryClient()

  const handleOk = () => {
    if (dayjs(initialTime).isSame(revokeTime)) {
      setMessage('새로운 회수 일시를 지정해주시기 바랍니다.')
      return
    }

    if (revokeTime.isBefore(dayjs().add(5, 'minutes'))) {
      setMessage('현재시간보다 5분 후로 설정해주세요.')
      return
    }

    mutateToCancelSchedulePlans(
      { scheduleId, revokeTime: revokeTime.format('YYYY-MM-DDTHH:mm:ss') },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: workspaceQueries.detail(workspaceId).queryKey })
          setMessage('')
          setModalOpened(false)
        },
        onError: (error) => {
          setMessage(error.message)
        },
      },
    )
  }

  return (
    <>
      <Button color="default" variant="filled" onClick={() => setModalOpened(true)}>
        일시 변경
      </Button>
      <Modal
        title="변경할 회수 일시를 지정해주세요."
        open={modalOpened}
        onOk={handleOk}
        onCancel={() => setModalOpened(false)}
        loading={isCancelingSchedulePlans}
      >
        <Space direction="vertical">
          <Typography.Text>
            새로운 회수 일시를 지정해주세요. 회수 일시는 현재 시간 기준 최소 5분 이후로 지정해 주세요.
          </Typography.Text>
          <DatePicker showTime value={revokeTime} onChange={(value) => setRevokeTime(value)} />
          <Typography.Text type="danger">{message}</Typography.Text>
        </Space>
      </Modal>
    </>
  )
}
