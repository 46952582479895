import apiClient from '../service/api'

// GET Plans
export const getPlans = async (page) => {
  const token = localStorage.getItem('token')

  const { data } = await apiClient.get(`/v1/plans`, {
    params: { page },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return data
}

export const getPlanDetails = async (planId) => {
  const token = localStorage.getItem('token')
  const response = await apiClient.get(`/v1/admin/plans/${planId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data
}
