import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button, Col, Popconfirm, Row, Table, Typography } from 'antd'
import { unschedulePlans, workspaceQueries } from '../../../../../Actions'
import AssignPlanButton from './AssignPlanButton'
import ChangeInvokeTimeButton from './ChangeInvokeTimeButton'

type Props = {
  workspaceId: number
}

export default function PlanHistory({ workspaceId }: Props) {
  const { data } = useQuery({
    ...workspaceQueries.detail(workspaceId)._ctx.schedulePlans(),
    select: (selectData) =>
      selectData.content.filter((schedule: any) =>
        ['COMPLETED', 'CANCELLED'].every(
          (status) => schedule.assignStatus !== status || schedule.revokeStatus !== status,
        ),
      ),
  })

  const { mutate: mutateToUnschedulePlans } = useMutation({
    mutationFn: unschedulePlans,
  })
  const queryClient = useQueryClient()

  return (
    <div>
      <Row>{data?.length === 0 && <AssignPlanButton workspaceId={workspaceId} />}</Row>
      <Row gutter={16}>
        <Col span={12}>
          <Row>
            <Typography.Title level={5}>부여 예약/적용 내역</Typography.Title>
          </Row>
          <Table
            dataSource={data}
            pagination={false}
            columns={[
              {
                title: 'assignTime',
                dataIndex: 'assignTime',
                key: 'assignTime',
              },
              {
                title: 'planName',
                dataIndex: 'planName',
                key: 'planName',
              },
              {
                title: 'issuerEmail',
                dataIndex: 'issuerEmail',
                key: 'issuerEmail',
              },
              {
                width: 100,
                render: (t, r: any) =>
                  r.assignStatus === 'PENDING' ? (
                    <Popconfirm
                      title="예약취소"
                      description="플랜 부여 예약을 취소하면 회수 예약도 함께 취소됩니다."
                      onConfirm={() => {
                        mutateToUnschedulePlans(r.id, {
                          onSuccess: () =>
                            queryClient.invalidateQueries({
                              queryKey: workspaceQueries.detail(workspaceId)._ctx.schedulePlans().queryKey,
                            }),
                        })
                      }}
                      okText="예"
                      cancelText="아니오"
                    >
                      <Button color="default" variant="filled">
                        예약 취소
                      </Button>
                    </Popconfirm>
                  ) : (
                    <Button color="primary" variant="filled">
                      적용 중
                    </Button>
                  ),
              },
            ]}
          />
        </Col>
        <Col span={12}>
          <Row>
            <Typography.Title level={5}>회수 예약 내역</Typography.Title>
          </Row>
          <Table
            dataSource={data}
            pagination={false}
            columns={[
              {
                title: 'revokeTime',
                dataIndex: 'revokeTime',
                key: 'revokeTime',
              },
              {
                title: 'planName',
                dataIndex: 'planName',
                key: 'planName',
              },
              {
                title: 'issuerEmail',
                dataIndex: 'issuerEmail',
                key: 'issuerEmail',
              },
              {
                width: 100,
                render: (t, r: any) =>
                  r.assignStatus === 'COMPLETED' && (
                    <ChangeInvokeTimeButton workspaceId={workspaceId} scheduleId={r.id} initialTime={r.revokeTime} />
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  )
}
