import { useQuery } from '@tanstack/react-query'
import { Table } from 'antd'
import React, { useState } from 'react'
import { workspaceQueries } from '../../../../../Actions'

type Props = {
  workspaceId: number
}

export default function Receipts({ workspaceId }: Props) {
  const { data } = useQuery({ ...workspaceQueries.detail(workspaceId)._ctx.receipts })
  const [page, setPage] = useState(1)

  const sortedData = [...(data ?? [])].sort((a, b) => b.createdAt.localeCompare(a.createdAt))

  return (
    <div>
      <Table
        dataSource={sortedData}
        pagination={{
          total: sortedData.length,
          current: page,
          onChange: (newPage) => setPage(newPage),
          position: ['bottomCenter'],
        }}
        // scroll={{ y: 55 * 10 }}
        columns={[
          {
            title: 'createdAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
          },
          {
            title: 'amount',
            dataIndex: 'amount',
            key: 'amount',
          },
          {
            title: 'memo',
            dataIndex: 'memo',
            key: 'memo',
            render: (t, r: any) => {
              switch (r.memo) {
                case 'welcome':
                  return '가입'
                case 'create movie':
                  return '영상 내보내기'
                case 'concat audios':
                  return '음성 내보내기'
                default:
                  return r.memo
              }
            },
          },
        ]}
      />
    </div>
  )
}
