import { createQueryKeys } from '@lukemorales/query-key-factory'
import apiClient from '../service/api'
import { PaginationParams } from '../types/list'

// GET Workspaces
export const getWorkspaces = async (page: number) => {
  const token = localStorage.getItem('token')
  const { data } = await apiClient.get(`/v1/workspaces?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return data
}

export const getWorkspaceDetails = async (workspaceId: number) => {
  const token = localStorage.getItem('token')
  const response = await apiClient.get(`/v1/workspaces/${workspaceId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data
}

export const getAvailableCredit = async (workspaceId: number) => {
  const token = localStorage.getItem('token')
  const response = await apiClient.get(`/v1/workspaces/${workspaceId}/credits`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.data
}

// eslint-disable-next-line consistent-return
export const rechargeCredits = async (requestBody: { workspaceId: number; amount: number; memo: string }) => {
  try {
    const token = localStorage.getItem('token')
    const response = await apiClient.post(`/v1/workspaces/${requestBody.workspaceId}/credits`, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    console.log(e)
  }
}

export const getSubscriptions = async (page: number, workspaceId: number) => {
  const token = localStorage.getItem('token')
  const { data } = await apiClient.get(`/v1/admin/workspaces/${workspaceId}/subscriptions?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return data
}

type GetScheduleCreditsListParams = PaginationParams & {
  min?: number
  max?: number
}

export const getScheduleCreditsList = async (workspaceId: number, params: GetScheduleCreditsListParams) => {
  const { data } = await apiClient.get(`/v1/workspaces/${workspaceId}/scheduleCredits`, {
    params: { ...params, status: 'PENDING' },
  })
  return data
}

export const scheduleCredits = async (requestBody: {
  workspaceId: number
  amount: number
  memo: string
  scheduledAt: string
}) => {
  const { data } = await apiClient.post(`/v1/workspaces/${requestBody.workspaceId}/scheduleCredits`, requestBody)
  return data
}

export const unscheduleCredits = async (scheduleId: number) => {
  const { data } = await apiClient.post(`/v1/workspaces/unscheduleCredits/${scheduleId}`)
  return data
}

export const schedulePlans = async (requestBody: {
  workspaceId: number
  planId: number
  assignTime: string
  revokeTime: string
}) => {
  const { data } = await apiClient.post(`/v1/workspaces/${requestBody.workspaceId}/schedulePlans`, requestBody)
  return data
}

export const unschedulePlans = async (scheduleId: number) => {
  const { data } = await apiClient.post(`/v1/workspaces/unSchedulePlans/${scheduleId}`)
  return data
}

export const changePlanRevocationDate = async ({
  scheduleId,
  ...requestBody
}: {
  scheduleId: number
  revokeTime: string
}) => {
  const { data } = await apiClient.patch(`/v1/workspaces/schedulePlans/${scheduleId}/revocationDate`, requestBody)
  return data
}

export const getSchedulePlans = async (workspaceId: number, params?: PaginationParams) => {
  const { data } = await apiClient.get(`/v1/workspaces/${workspaceId}/schedulePlans`, { params })
  return data
}

export const getWorkspaceReceipts = async (workspaceId: number) => {
  const { data } = await apiClient.get(`/v1/workspaces/${workspaceId}/receipts`)
  return data
}

export const workspaceQueries = createQueryKeys('workspace', {
  detail: (workspaceId: Parameters<typeof getWorkspaceDetails>[0]) => ({
    queryKey: [workspaceId],
    queryFn: () => getWorkspaceDetails(workspaceId),
    contextQueries: {
      credits: {
        queryKey: null,
        queryFn: () => getAvailableCredit(workspaceId),
      },
      scheduleCredits: (params: GetScheduleCreditsListParams) => ({
        queryKey: [params],
        queryFn: () => getScheduleCreditsList(workspaceId, params),
      }),
      schedulePlans: (params?: PaginationParams) => ({
        queryKey: [params],
        queryFn: () => getSchedulePlans(workspaceId, params),
      }),
      receipts: {
        queryKey: null,
        queryFn: () => getWorkspaceReceipts(workspaceId),
      },
    },
  }),
})
