import { useMutation, useQuery } from '@tanstack/react-query'
import {
  Button,
  DatePicker,
  DatePickerProps,
  Flex,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Space,
  Table,
  Typography,
} from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { rechargeCredits, scheduleCredits, unscheduleCredits, workspaceQueries } from '../../../../../Actions'

type RechargeType = 'IMMEDIATE' | 'SCHEDULED'

type Props = {
  workspaceId: number
}

export default function RechargeCredits({ workspaceId }: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [rechargeType, setRechargeType] = useState<RechargeType>('IMMEDIATE')
  const [rechargeAmount, setRechargeAmount] = useState('')
  const [scheduleDate, setScheculeDate] = useState<DatePickerProps['value']>()
  const [modalMessage, setModalMessage] = useState('')
  const { mutateAsync: mutateToRechargeCredits } = useMutation({ mutationFn: rechargeCredits })
  const { mutateAsync: mutateToScheduleCredits } = useMutation({ mutationFn: scheduleCredits })
  const { mutate: mutateToUnscheduleCredits } = useMutation({ mutationFn: unscheduleCredits })
  const { data, refetch: refetchScheduledCreditsList } = useQuery({
    ...workspaceQueries.detail(workspaceId)._ctx.scheduleCredits({ min: 0 }),
  })
  const [isLoading, setIsLoading] = useState(false)

  const { refetch: refetchCredits } = useQuery({
    ...workspaceQueries.detail(Number(workspaceId))._ctx.credits,
    enabled: !!workspaceId,
  })

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleRecharge = async () => {
    try {
      setIsLoading(true)

      if (!rechargeAmount) {
        setModalMessage('크레딧을 입력해주세요.')
        return
      }

      const amount = parseFloat(rechargeAmount)

      if (rechargeType === 'SCHEDULED') {
        if (!scheduleDate) {
          setModalMessage('예약일시를 적어주세요.')
          return
        }
        await mutateToScheduleCredits({
          workspaceId,
          amount,
          memo: '어드민 부여',
          scheduledAt: scheduleDate.format('YYYY-MM-DDTHH:mm:ss'),
        })
      } else {
        await mutateToRechargeCredits({
          workspaceId,
          amount,
          memo: '어드민 부여',
        })
      }

      await refetchCredits()
    } finally {
      // cleanup
      setIsModalVisible(false)
      setRechargeAmount('0')
      setModalMessage('')
      setIsLoading(false)
      await refetchScheduledCreditsList()
    }
  }

  const handleScheduleChanged = (value: DatePickerProps['value']) => {
    setScheculeDate(value)
  }

  return (
    <>
      <div>
        <Flex justify="flex-start">
          <Button onClick={showModal}>충전하기</Button>
        </Flex>

        <Typography.Title level={5}>충전 예약 내역</Typography.Title>
        <Table
          dataSource={data?.content}
          pagination={false}
          columns={[
            {
              title: 'scheduledAt',
              dataIndex: 'scheduledAt',
              key: 'scheduledAt',
            },
            {
              title: 'amount',
              dataIndex: 'amount',
              key: 'amount',
            },
            {
              title: 'issuerEmail',
              dataIndex: 'issuerEmail',
              key: 'issuerEmail',
              render: (t, r: any) => <Link to={`/user/${r.issuerId}`}>{r.issuerEmail}</Link>,
            },
            {
              width: 100,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              render: (t, r: any) => (
                <Popconfirm
                  title="예약취소"
                  description="예약을 취소하시겠습니까?"
                  onConfirm={() => {
                    mutateToUnscheduleCredits(r.id, { onSuccess: () => refetchScheduledCreditsList() })
                  }}
                  okText="예"
                  cancelText="아니오"
                >
                  <Button>예약취소</Button>
                </Popconfirm>
              ),
            },
          ]}
          scroll={{ y: 65 * 10 }}
        />
      </div>
      <Modal
        title="크레딧 충전"
        open={isModalVisible}
        onOk={handleRecharge}
        onCancel={handleCancel}
        loading={isLoading}
      >
        <Space direction="vertical" size="middle">
          <Input
            style={{ marginTop: '16px' }}
            type="number"
            value={rechargeAmount}
            min={0}
            placeholder="충전할 크레딧"
            onChange={(e) => setRechargeAmount(e.target.value)}
          />
          <Radio.Group value={rechargeType} onChange={(e) => setRechargeType(e.target.value)}>
            <Space direction="vertical">
              <Radio value={'IMMEDIATE' satisfies RechargeType}>즉시 충전하기</Radio>
              <Radio value={'SCHEDULED' satisfies RechargeType}>충전 예약하기</Radio>
            </Space>
          </Radio.Group>
          {rechargeType === 'SCHEDULED' ? (
            <DatePicker
              showTime
              onChange={(value, dateString) => {
                console.log('Selected Time: ', value)
                console.log('Formatted Selected Time: ', dateString)
                handleScheduleChanged(value)
              }}
              // onOk={handleScheduleChanged}
              value={scheduleDate}
            />
          ) : null}
          {modalMessage ? <Typography.Text type="danger">{modalMessage}</Typography.Text> : null}
        </Space>
      </Modal>
    </>
  )
}
